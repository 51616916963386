<template>
  <div>
    <BaseHeaderW color="primary"></BaseHeaderW>
    <div>
      <p class="loading" v-if="!dataLoaded">
        We are gathering properties from a range of sources this, please do not
        refresh....
      </p>
      <v-progress-linear
        v-if="!dataLoaded"
        v-model="value"
        color="accent"
        :buffer-value="bufferValue"
      ></v-progress-linear>
    </div>
    <div v-if="auth.User.isPremium === true">
      <v-container fluid v-if="dataLoaded">
        <div>
          <v-switch disabled>
            <template v-slot:label> View Sold STC</template>
          </v-switch>
        </div>
        <span
          v-for="(property, index) in this.$store.getters.SearchResultsOrdered"
          :key="index"
        >
          <v-card
            class="propCards"
            v-if="
              property.Images &&
              !property.tags.includes('SOLD_STC') &&
              !property.tags.includes('UNDER_OFFER')
            "
          >
            <v-carousel v-if="property.Images" height="210" hide-delimiters>
              <span v-for="(item, i) in property.Images" :key="i">
                <v-carousel-item
                  v-if="property.IDtype === 'R'"
                  :src="item.resizedImageUrls.size656x437"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  width="cover"
                ></v-carousel-item>
                <v-carousel-item
                  v-if="property.IDtype === 'Z'"
                  :src="'https://lid.zoocdn.com/u/1600/1200/' + item.filename"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  width="cover"
                ></v-carousel-item>
              </span>
            </v-carousel>
            <v-img
              v-show="!property.Images"
              src="../../public/comingsoon.jpg"
              style="object-fit: cover; height: 250px"
            />
            <div class="pa-1" v-for="tag in property.tags" :key="tag">
              <v-chip outlined color="purple">{{ tag }}</v-chip>
            </div>
            <v-card-text>
              <div>Guide Price</div>
              <p class="text-h4 text--primary">
                £{{
                  property.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
              <div>{{ property.address }}</div>
              <p>
                <v-icon>mdi-bed</v-icon> {{ property.bedrooms }} <span> </span>
                <v-icon>mdi-toilet</v-icon>{{ property.bathrooms }}
              </p>
              <p style="font-weight: 800">{{ property.subtype }}</p>
            </v-card-text>
            <!--https://www.rightmove.co.uk/properties/-->
            <v-card-actions>
              <v-btn
                v-if="auth.User.isPremium === true"
                class="mx-auto"
                style="width: 100%"
                color="primary"
                @click="propertyDetails(index)"
              >
                View Property Defect Report
              </v-btn>
            </v-card-actions>
          </v-card>
        </span>
        <v-skeleton-loader
          class="propCards"
          v-bind="attrs"
          v-if="!search.datapartloaded"
          type="card, list-item-two-line, list-item"
        ></v-skeleton-loader>
      </v-container>
    </div>
    <div v-if="auth.User.isPremium === false && dataLoaded">
      <v-banner single-line @click:icon="alert" color="secondary" sticky>
        <v-icon slot="icon" color="white" size="36">
          mdi-alert-decagram
        </v-icon>
        <span
          >We have identified over 50 properties in your area, start your free
          trial to get unlimited reports.</span
        >

        <template v-slot:actions>
          <v-btn color="primary" text @click.native="$router.push('/Profile')">
            Free Trial
          </v-btn>
        </template>
      </v-banner>
      <span
        v-for="(property, index) in this.$store.getters.SearchResultsOrdered"
        :key="index"
      >
        <v-card
          class="propCards"
          v-if="
            property.Images &&
            !property.tags.includes('SOLD_STC') &&
            !property.tags.includes('UNDER_OFFER')
          "
        >
          <v-carousel v-if="property.Images" height="210" hide-delimiters>
            <span v-for="(item, i) in property.Images" :key="i">
              <v-carousel-item
                v-if="property.IDtype === 'R'"
                :src="item.resizedImageUrls.size656x437"
                reverse-transition="fade-transition"
                transition="fade-transition"
                width="cover"
              ></v-carousel-item>
              <v-carousel-item
                v-if="property.IDtype === 'Z'"
                :src="'https://lid.zoocdn.com/u/1600/1200/' + item.filename"
                reverse-transition="fade-transition"
                transition="fade-transition"
                width="cover"
              ></v-carousel-item>
            </span>
          </v-carousel>
          <v-img
            v-show="!property.Images"
            src="../../public/comingsoon.jpg"
            style="object-fit: cover; height: 250px"
          />
          <div class="pa-1" v-for="tag in property.tags" :key="tag">
            <v-chip outlined color="purple">{{ tag }}</v-chip>
          </div>
          <v-card-text>
            <div>Guide Price</div>
            <p class="text-h4 text--primary">
              £{{
                property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </p>
            <div>{{ property.address }}</div>
            <p>
              <v-icon>mdi-bed</v-icon> {{ property.bedrooms }} <span> </span>
              <v-icon>mdi-toilet</v-icon>{{ property.bathrooms }}
            </p>
            <p style="font-weight: 800">{{ property.subtype }}</p>
            <p>
              Powered by
              <span v-if="property.IDtype === 'Z'">Zoopla</span>
              <span v-if="property.IDtype === 'R'">Rightmove</span>
            </p>
          </v-card-text>
          <!--https://www.rightmove.co.uk/properties/-->
          <v-card-actions>
            <v-btn
              v-if="auth.User.isPremium === true"
              class="mx-auto"
              style="width: 100%"
              color="primary"
              @click="propertyDetails(index)"
            >
            </v-btn>
          </v-card-actions>
        </v-card>
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data: () => ({
    searchQuery: {},
    dataLoaded: false,
    partdataLoaded: false,
    tempStore: [],
    value: 5,
    bufferValue: 10,
    interval: 0,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 4,
      height: "450px",
      loading: true,
    },
  }),
  async created() {
    await this.validatePostcode.then(async (response) => {
      console.log(response.data.result);
      if (response.data.result === true) {
        const payload = {
          postcode: this.$route.params.search,
          radius: this.$route.query.radius,
          results: 25,
          userid: this.auth.UserId,
        };
        await this.$store
          .dispatch("getSearchResultsv2", payload)
          .then((response) => {
            console.log(response);
            this.dataLoaded = true;
          });
      } else {
        alert(
          "Something is not right, are you sure you entered a valid postcode?"
        );
        this.$router.push("/");
      }
    });
  },
  methods: {
    propertyDetails(val) {
      localStorage.setItem(
        "property",
        JSON.stringify(this.$store.getters.SearchResultsOrdered[val])
      );
      this.$router.push({
        name: "About",
        params: { id: val },
      });
    },
    startBuffer() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.value += Math.random() * (15 - 5) + 5;
        this.bufferValue += Math.random() * (15 - 5) + 6;
      }, 2000);
    },
  },
  mounted() {
    this.startBuffer();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    value(val) {
      if (val < 100) return;
      this.value = 0;
      this.bufferValue = 10;
      this.startBuffer();
    },
    watchingPropertyChanges() {
      this.tempStore = this.search.SearchResults;
    },
  },
  computed: {
    /*
        fliteredlist() {
          const minPrice = this.$route.query.minPrice;
          const maxPrice = this.$route.query.maxPrice;
          const minBed = this.$route.query.minBed;
          const maxBed = this.$route.query.maxBed;
          const type = this.$route.query.propType;
          return this.search.SearchResults.property.filter((property) => {
            if (
              property.price >= minPrice &&
              property.price <= maxPrice &&
              property.bedrooms >= minBed &&
              property.bedrooms <= maxBed &&
              property.type === type
            ) {
              return true;
            } else if (
              property.price === "all" &&
              property.price <= "all" &&
              property.bedrooms >= "all" &&
              property.bedrooms <= "all" &&
              property.type === "all"
            ) {
              return true;
            } else {
              return false;
            }
          });
        }, */
    validatePostcode() {
      console.log("validatePostcode:", this.$route.params.search);
      return axios.get(
        "https://api.postcodes.io/postcodes/" +
          this.$route.params.search +
          "/validate"
      );
    },
    idsplit(id) {
      const x = id.match(/[a-zA-Z]+|[0-9]+/g);
      return x[0];
    },
    ...mapState(["search", "auth"]),
  },
};
</script>

<style>
.propCards {
  display: inline-block !important;
  width: 24%;
  border-radius: 0px !important;
  margin: 0.5%;
}
.loading {
  padding: 5%;
  margin-left: 15%;
  margin-top: 10%;
}
@media screen and (max-width: 850px) {
  .propCards {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .loading {
    margin-left: 15%;
    margin-top: 55%;
  }
}
@media screen and (max-width: 850px) {
  .loading {
    margin-left: 5%;
    margin-top: 25%;
  }
}
</style>
